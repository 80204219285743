import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import { render } from '@testing-library/react';

import './index.css';

import Menu from './Sections/menu.js';
import SlideForm from './Sections/slide-form.js';
import HomePage from './Pages/home.js';
import ComingSoon from './Pages/coming-soon.js';

const App = () => (
  <BrowserRouter>
    <Menu />
      <Route path="/" component = {HomePage} exact /> 
      <Route path="/projects/" component = {ComingSoon} exact />
    <SlideForm />
  </BrowserRouter>
);

render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
