import { render } from '@testing-library/react';
import React from 'react';
import '../index.css';
import FullHero from '../Sections/FullHero.js';

class homePage extends React.Component
{
    constructor(props)
    {
      super(props);
  
      this.openSlideForm = this.openSlideForm.bind(this);
    }

    openSlideForm()
    {
        document.getElementById("slideForm").classList.remove("closed");
        document.getElementById("contact-btn").classList.add("btn-open");
    } 

    render()
    {
        return(
            <FullHero
                heading1={"Coming Soon..."}
                subtext={["I'm still putting together a collection of my work for the public.", <br />, "Feel free to ", <a href="#" onClick={this.openSlideForm}>contact me</a>," and make a request.", <br />,<br />, "You may also visit ", <a href="https://jhentools.com" target="_blank">jhentools.com</a>]}
            />
        )
    }
}

export default homePage;
