import './menu.css';
import React from 'react';

class Menu extends React.Component
{
    constructor(props)
    {
      super(props);
  
      this.openSlideForm = this.openSlideForm.bind(this);
    }

    openSlideForm()
    {
        document.getElementById("slideForm").classList.remove("closed");
        document.getElementById("contact-btn").classList.add("btn-open");
    } 

    render()
    {
        return(
            <header className="site-menu">
            <a href="/" id="site-title">James Henshall.</a>
    
            <div className="hamburger-toggle"
            onClick={function()
            {
                let nav = document.getElementById("navigation");
                if (nav.classList.contains("active"))
                {
                   nav.classList.remove("active"); 
                   console.log();
                }
                else
                {
                    nav.classList.add("active");
                }
            }}
            >
                <div></div>
                <div></div>
                <div></div>
            </div>
    
            <div id="navigation" className="navigation">
                <ul>
                    <li><a href="/projects/">Projects</a></li>
                    <li id="contact-btn" onClick={this.openSlideForm}>Contact Me</li>
                </ul>
            </div>
    
          </header>
        );
    }
}

export default Menu;