import React from 'react';
import '../index.css';
import FullHero from '../Sections/FullHero.js';

function homePage()
{
    return(
        <FullHero 
            heading1={"G'day, I'm James."}
            subtext={"Full-stack developer & SEO Expert."}
        />
    )
}

export default homePage;
