import React from 'react';
import './fullhero.css';

class FullHeroTemplate extends React.Component
{
    constructor(props)
    {
      super(props);
    }

    render()
    {
        return(
            <section id="home-greeting">
                <div className="heading-container">
                    <h1>{this.props.heading1}</h1>
                    <p>{this.props.subtext}</p>
                </div>
            </section>
        )
    }

}

FullHeroTemplate.defaultPorps =
{
    heading1: "",
    subtext: ""
}

// function homeGreeting()
// {
//     constructor(props)
//     {
//       super(props);
//     }

//     return(
//         <section id="home-greeting">
//             <div className="heading-container">
//                 <h1>G'day, I'm James.</h1>
//                 {this.state.test}
//                 <p>Full-stack developer & SEO Expert.</p>
//             </div>
//         </section>
//     )
// }

export default FullHeroTemplate;