import './slide-form.css';
import React from 'react';

class SlideForm extends React.Component
{
  constructor(props)
  {
    super(props);
    
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.removeError = this.removeError.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }

  clearForm(event)
  {
    document.getElementsByTagName("form")[0].reset();
  }
  
  handleChange(event)
  {
    this.setState({value: event.target.value});
  }

  removeError(event)
  {
    event.target.classList.remove("input-error");
  }

  Validation(data)
  {
    let errorBox = document.getElementById("slide-form-errors");
    console.log(data);
    // Process errors or success
    try
    {
      let response = JSON.parse(data);
      if (response["Error"])
      {
        errorBox.innerText = response["Error"];
      }

      else if (response["Success"])
      {
        errorBox.innerText = response["Success"];
        errorBox.style.color = "#53ad42";
        errorBox.style.fontSize = "18px";
        this.clearForm();
      }

      // Multi part (array) response is expected
      else
      {
        console.log(response);
        errorBox.innerText = "Invalid fields:";
        response.forEach(function(item, index)
        {
          if (index === response.length -1)
          {
            errorBox.innerText += ` ${item}.`;
          }
          else
          {
            errorBox.innerText += ` ${item},`;
          }
          
          document.getElementById(`slide-form-${item}`).classList.add("input-error");
        });
      }
      document.getElementById("slide-form-errors").style.display = "block";
    }
    catch (e)
    {
      errorBox.innerText = e;
    }
  }
  
  handleSubmit(event)
  {
    event.preventDefault();

    // Basic client side validation
    try
    {
      var _name = document.getElementById("slide-form-name").value;
      var _email = document.getElementById("slide-form-email").value;
      var _phone = document.getElementById("slide-form-phone").value;
      var _message = document.getElementById("slide-form-message").value;

      if (!_email.includes('@'))
      {
        this.Validation(`["email"]`);
        return;
      }
    }
    catch (e)
    {
      this.Validation(`{"Error": "Unrecognised or contaminated form data. I hope you aren't being cheeky ;)"}`);
      return;
    }
    
    // Send form data to mail handler.
    var formData = new FormData();
    formData.append('name', _name);
    formData.append('email', _email);
    formData.append('phone', _phone);
    formData.append('message', _message);
    
    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://mess.jameshenshall.com.au/mail_handler/", true);
    xhr.send(formData);
    
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) 
      {
        this.Validation(xhr.response);
      }
    }
  }
  
  closeForm()
  {
    document.getElementById("slideForm").classList.add("closed");
    document.getElementById("contact-btn").classList.remove("btn-open");
  }
  render()
  {
    return(
      <div id="slideForm" className="slide-form-container closed">
      <div className="close-me" onClick={this.closeForm}>X</div>
      <h3>Get in touch</h3>
      <p>Feel free to get in touch with me if you think I might be able to help your or your businesss.</p>
      <form onSubmit={this.handleSubmit}>
      <div className="form-fields-wrapper">
      <div className="input-container">
      <input type="text" id="slide-form-name" name="slide-form-name" placeholder="Name" onChange={this.handleChange} onFocus={this.removeError}/>
      <input type="text" id="slide-form-email" name="slide-form-email" placeholder="Email" onChange={this.handleChange} onFocus={this.removeError} required/>
      <input type="tel" id="slide-form-phone" name="slide-form-phone" placeholder="Phone" onChange={this.handleChange} onFocus={this.removeError}/>
      </div>
      <div className="input-container">
      <textarea id="slide-form-message" placeholder="What would you like to chat about?" onFocus={this.removeError} required/>
      </div>
      </div>
      <div className="form-errors" id="slide-form-errors"></div>
      <input className="submit-btn" type="submit" value="Send" />
      </form>
      
      </div>
      );
    }
  }
  
  export default SlideForm;
  